var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-form"},[_c('form',{ref:"registerForm",attrs:{"action":"javascript:void(0)"}},[(_vm.step === 1)?_c('div',{staticClass:"register-step"},[_c('TextField',{staticClass:"mb-8",attrs:{"type":'email',"label":'Email*',"rules":[
          _vm.Rules.isRequired(
            _vm.email,
            'Для восстановления пароля нужно заполнить Email адрес'
          ),
          _vm.Rules.isCorrectEmail(_vm.email, 'Некорректный email') ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"register-step__button button_pink button",on:{"click":function($event){return _vm.validate(2, _vm.restorePasswordStart, _vm.email)}}},[_vm._v(" Продолжить ")])],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"register-step"},[(!_vm.showHelp)?_c('div',[_c('div',{staticClass:"register-step__title"},[_vm._v("Проверьте почту")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Отправили письмо с кодом на "+_vm._s(_vm.email)+". "),_c('span',{staticClass:"register-step__description_pink",on:{"click":function($event){_vm.step--}}},[_vm._v("Изменить email?")])]),_c('div',{staticClass:"register-step__help",on:{"click":function($event){_vm.showHelp = true}}},[_vm._v(" Письмо не пришло ")])]):_c('div',[_c('div',{staticClass:"header-auth__help-caption"},[_vm._v("Что делать")]),_vm._m(0),_c('div',{staticClass:"header-auth__back button button_empty-pink",on:{"click":function($event){_vm.showHelp = false}}},[_vm._v(" Назад ")])])]):_vm._e(),(_vm.step === 3)?_c('div',{staticClass:"register-step"},[_c('TextField',{staticClass:"mb-8 pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Введите пароль*',"rules":[
          _vm.Rules.isRequired(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.onlyLatin(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.minLength(_vm.password, 'Пароль не соответствует требованиям'),
          _vm.Rules.digitsReq(_vm.password, 'Пароль не соответствует требованиям'),
          !_vm.inputError || _vm.inputError
        ],"successState":(_vm.Rules.isRequired(_vm.password, false) &&
          _vm.Rules.onlyLatin(_vm.password, false) &&
          _vm.Rules.minLength(_vm.password, false) &&
          _vm.Rules.digitsReq(_vm.password, false) &&
          !_vm.inputError || false)},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",class:{ 'show-icon_active': _vm.showPass },on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"currentColor","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"currentColor","stroke-linecap":"square"}})])])]},proxy:true},{key:"alternate-error",fn:function(){return [_c('div',{staticClass:"pass-requirements"},[_c('div',{staticClass:"req__title"},[_vm._v("Требования к паролю")]),_c('ul',{staticClass:"req__list mt-2"},[_c('li',{staticClass:"req__point mb-2",class:[
                  _vm.Rules.onlyLatin(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Только латинские буквы ")]),_c('li',{staticClass:"req__point",class:[
                  _vm.Rules.minLength(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум 8 символов ")]),_c('li',{staticClass:"req__point",class:[
                  _vm.Rules.digitsReq(_vm.password, false) ? 'success' : 'error' ]},[_vm._v(" Минимум одна цифра ")])])])]},proxy:true}],null,false,2140949252),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('TextField',{staticClass:"pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"label":'Повторите пароль*',"rules":[
          _vm.Rules.isRequired(_vm.passwordRepeat, 'Пароли не совпадают'),
          _vm.passwordRepeat === _vm.password || 'Пароли не совпадают' ],"successState":_vm.passwordRepeat === _vm.password},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('div',{staticClass:"show-icon",class:{ 'show-icon_active': _vm.showPass },on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z","stroke":"currentColor","stroke-linecap":"square"}}),_c('circle',{attrs:{"cx":"8","cy":"8","r":"2.5","stroke":"currentColor","stroke-linecap":"square"}})])])]},proxy:true}],null,false,3111249292),model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('div',{staticClass:"buttons-container"},[_c('div',{staticClass:"register-step__button button_pink big-button button",on:{"click":function($event){return _vm.validate(4, _vm.restorePassword, _vm.passData)}}},[_vm._v(" Продолжить ")])])],1):_vm._e(),(_vm.step === 4)?_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"register-step__title"},[_vm._v("Пароль восстановлен")]),_c('div',{staticClass:"register-step__description"},[_vm._v(" Теперь можете перейти на главную страницу и войти на сайт, используя введите пароль. ")]),_c('router-link',{staticClass:"register-step__button button_pink button",attrs:{"to":{ name: 'MainPage' }}},[_vm._v("На главную")])],1):_vm._e()]),_c('RegisterFooter')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"header-auth__help-list"},[_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Вы могли ввести несуществующий email или ошибиться в букве. Пожалуйста, проверьте всё ещё раз. ")]),_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Проверьте папку «Спам» — возможно, письмо попало туда. ")])])}]

export { render, staticRenderFns }