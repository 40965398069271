<template>
  <div class="register-form">
    <form ref="registerForm" action="javascript:void(0)">
      <div class="register-step" v-if="step === 1">
        <TextField
          :type="'email'"
          v-model="email"
          :label="'Email*'"
          :rules="[
            Rules.isRequired(
              email,
              'Для восстановления пароля нужно заполнить Email адрес'
            ),
            Rules.isCorrectEmail(email, 'Некорректный email'),
          ]"
          class="mb-8"
        />
        <div
          class="register-step__button button_pink button"
          @click="validate(2, restorePasswordStart, email)"
        >
          Продолжить
        </div>
      </div>
      <div class="register-step" v-if="step === 2">
        <div v-if="!showHelp">
          <div class="register-step__title">Проверьте почту</div>
          <div class="register-step__description">
            Отправили письмо с кодом на {{ email }}.
            <span class="register-step__description_pink" @click="step--"
              >Изменить email?</span
            >
          </div>
          <div @click="showHelp = true" class="register-step__help">
            Письмо не пришло
          </div>
        </div>
        <div v-else>
          <div class="header-auth__help-caption">Что делать</div>
          <ol class="header-auth__help-list">
            <li class="header-auth__help-list-item">
              Вы могли ввести несуществующий email или ошибиться в букве.
              Пожалуйста, проверьте всё ещё раз.
            </li>
            <li class="header-auth__help-list-item">
              Проверьте папку «Спам» — возможно, письмо попало туда.
            </li>
          </ol>
          <div
            class="header-auth__back button button_empty-pink"
            @click="showHelp = false"
          >
            Назад
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 3">
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="password"
          :label="'Введите пароль*'"
          :rules="[
            Rules.isRequired(password, 'Пароль не соответствует требованиям'),
            Rules.onlyLatin(password, 'Пароль не соответствует требованиям'),
            Rules.minLength(password, 'Пароль не соответствует требованиям'),
            Rules.digitsReq(password, 'Пароль не соответствует требованиям'),
            !inputError || inputError
          ]"
          :successState="
            (Rules.isRequired(password, false) &&
            Rules.onlyLatin(password, false) &&
            Rules.minLength(password, false) &&
            Rules.digitsReq(password, false) &&
            !inputError || false)
          "
          class="mb-8 pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
          <template v-slot:alternate-error>
            <div class="pass-requirements">
              <div class="req__title">Требования к паролю</div>
              <ul class="req__list mt-2">
                <li
                  class="req__point mb-2"
                  :class="[
                    Rules.onlyLatin(password, false) ? 'success' : 'error',
                  ]"
                >
                  Только латинские буквы
                </li>

                <li
                  class="req__point"
                  :class="[
                    Rules.minLength(password, false) ? 'success' : 'error',
                  ]"
                >
                  Минимум 8 символов
                </li>

                <li
                  class="req__point"
                  :class="[
                    Rules.digitsReq(password, false) ? 'success' : 'error',
                  ]"
                >
                  Минимум одна цифра
                </li>
              </ul>
            </div>
          </template>
        </TextField>
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="passwordRepeat"
          :label="'Повторите пароль*'"
          :rules="[
            Rules.isRequired(passwordRepeat, 'Пароли не совпадают'),
            passwordRepeat === password || 'Пароли не совпадают',
          ]"
          :successState="passwordRepeat === password"
          class="pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
        </TextField>

        <div class="buttons-container">
          <div
            class="register-step__button button_pink big-button button"
            @click="validate(4, restorePassword, passData)"
          >
            Продолжить
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 4">
        <div class="register-step__title">Пароль восстановлен</div>
        <div class="register-step__description">
          Теперь можете перейти на главную страницу и войти на сайт, используя
          введите пароль.
        </div>
        <router-link
          :to="{ name: 'MainPage' }"
          class="register-step__button button_pink button"
          >На главную</router-link
        >
      </div>
    </form>
    <RegisterFooter />
  </div>
</template>

<script>
import RegisterFooter from "./RegisterFooter.vue";
import TextField from "@/components/form-elements/TextField.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main";
export default {
  components: {
    TextField,
    RegisterFooter,
  },
  name: "RestoreForm",
  data: () => ({
    showHelp: false,
    showPass: false,
    email: "",
    personalData: false,
    step: 1,
    password: "",
    passwordRepeat: "",
  }),
  computed: {
    ...mapGetters(["Rules", "restoreSmsConfirmed", 'inputError']),
    passData() {
      return {
        restore_id: this.$route.query.uuid,
        new_password: this.password,
        new_password_confirm: this.passwordRepeat
      };
    },
  },
  methods: {
    ...mapActions([
      "restorePasswordStart",
      "restorePhoneResend",
      "restorePhoneCode",
      "restorePassword",
      "clearInputError"
    ]),
    async validate(toStep, callback, arg) {
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            bus.data.result = [];
            this.step = toStep;
          }
        } else {
          bus.data.result = [];
          if (toStep) {
            this.step = toStep;
          }
        }
      }
      bus.data.result = [];
    },
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.step = 3;
    }
  },
  watch: {
    step() {
      let hideLeft = this.step === 4;
      this.$emit("changeStep", "", hideLeft);
    },
  async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async password() {
      if(this.inputError) {
        this.clearInputError()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  margin: 0 auto;
  width: 592px;

  @media screen and (max-width: 1220px) {
    width: 100%;
    margin: 40px 0 0;
  }

  @media screen and (max-width: 767px) {
    &:only-child {
      margin-top: 0;
    }
  }
}

.register-step {
  &__help {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
  &__button.back {
    width: 92px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
  &__button {
    margin-top: 32px;
    width: 162px;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .big-button {
    width: 229px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .buttons-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #3c4242;
    cursor: pointer;

    &_active {
      color: #d0006f;
    }
  }
}
.header-auth {
  &__help-caption {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #424753;
  }

  &__help-list {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #666666;
    padding-left: 15px;
    list-style-type: number;
  }

  &__help-list-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__back {
    margin-top: 16px;
    display: inline-flex;
  }
}
</style>
